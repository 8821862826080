<template>
  <div class="transactionDemo">
    <div class="transaction_top">
      <div class="transaction_top_symbol">
        <tran-symbol></tran-symbol>
      </div>
      <div class="transaction_top_kline">
        <k-line-mn></k-line-mn>
      </div>
      <div class="transaction_top_right">
        <offer-analysis-rule></offer-analysis-rule>
      </div>
    </div>
    <div class="transaction_bottom">
      <div class="transaction_bottom_assets">
        <assets-demo></assets-demo>
      </div>
      <div class="transaction_bottom_warehouse">
        <order-list-demo></order-list-demo>
      </div>
    </div>
  </div>
</template>

<script>
import tranSymbol from "./composition/TranSymbol";
import KLineMn from "./composition/KLineMn2";
import offerAnalysisRule from "./composition/offerAnalysisRule";
import orderListDemo from "./composition/orderListDemo";
import assetsDemo from "./composition/assetsDemo";

export default {
  name: "transaction",
  components: {
    tranSymbol,
    KLineMn,
    offerAnalysisRule,
    orderListDemo,
    assetsDemo,
  },
  setup() {},
};
</script>
<style lang="less">
.transactionDemo {
  height: 1160px;

  /*no*/
  .transaction_top {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .transaction_top_symbol {
      width: 310px;
      /*no*/
      height: 568px;
      /*no*/
      background: @white;
    }

    .transaction_top_kline {
      width: calc(100% - 630px);
      /*no*/
      height: 568px;
      /*no*/
      background: @white;
      position: relative;
      z-index: 9;
    }

    .transaction_top_right {
      width: 300px;
      /*no*/
      height: 568px;
      /*no*/
      background: @white;
    }
  }

  .transaction_bottom {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .transaction_bottom_warehouse {
      width: calc(100% - 320px);
      /*no*/
      height: 576px;
      /*no*/
      background: @white;
    }

    .transaction_bottom_assets {
      width: 310px;
      /*no*/
      height: 576px;
      /*no*/
      background: @white;
    }
  }
}
</style>
