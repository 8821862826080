<template>
  <div class="position_data">
    <div class="order_list_title">
      <div class="position">
        <div class="close_btn">
          <div
            class="close_all cursor"
            v-if="dataPosition.length != 0"
            @click="closeAllPos"
          >
            {{ $t("trade.text49") }}
          </div>
        </div>
      </div>
      <div class="position_table">
        <el-table
          height="390px"
          v-loading="tloading"
          :data="dataPosition"
          size="small"
          :empty-text="$t('trade.text66')"
        >
          <el-table-column
            fixed="left"
            prop="symbol"
            :label="$t('trade.text50')"
          />
          <el-table-column prop="bsType" :label="$t('trade.text51')">
            <template #default="scope">
              <span :class="scope.row.bsType == 1 ? 'up' : 'down'">{{
                scope.row.bsType == 1 ? $t("trade.text30") : $t("trade.text32")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            :label="$t('trade.text52')"
            width="50"
          />
          <el-table-column prop="openPrice" :label="$t('trade.text53')" />
          <el-table-column prop="marketPrice" :label="$t('trade.text54')" />
          <el-table-column
            prop="profit"
            :label="$t('trade.text55')"
            min-width="90"
          >
            <template #default="scope">
              <span
                :class="scope.row.profit.indexOf('-') == -1 ? 'up' : 'down'"
                >{{ scope.row.profit }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="margin" :label="$t('trade.text56')" />
          <el-table-column prop="swap" :label="$t('trade.text57')" />
          <el-table-column
            prop="stopProfit"
            min-width="120"
            :label="$t('trade.text58')"
          />
          <el-table-column
            prop="stopLoss"
            min-width="110"
            :label="$t('trade.text59')"
          />
          <el-table-column
            prop="posTime"
            :label="$t('trade.text60')"
            width="140px"
          />
          <el-table-column
            prop="orderNo"
            min-width="140"
            :label="$t('trade.text61')"
          />
          <el-table-column
            :label="$t('trade.text62')"
            width="280px"
            fixed="right"
          >
            <template #default="scope">
              <span class="operate">
                <el-button link @click="closePosAll(scope.row)">{{
                  $t("trade.text63")
                }}</el-button>
                <el-button
                  link
                  @click="closePos(scope.row, 2)"
                  v-if="!isMasterAccount"
                  >{{ $t("trade.text149") }}</el-button
                >
                <el-button link @click="oneClickReversePos(scope.row)">{{
                  $t("trade.text64")
                }}</el-button>
                <el-button
                  link
                  class="profit_and_stop"
                  @click="showStopProfitAndStopLoss(scope.row)"
                  >{{ $t("trade.text65") }}</el-button
                >
              </span>
            </template>
          </el-table-column>
          <template #empty>
            <div class="empty" v-if="userToken">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/accountInformation/icon_no_data%402x.png"
                alt=""
              />
              <p>{{ $t("trade.text66") }}</p>
            </div>
            <div v-else class="no_login">
              <img
                src="https://advantrade.oss-accelerate.aliyuncs.com/web/icon_login%402x.png"
                alt=""
              />
              <el-button @click="goLogin" class="login_btn" type="primary">{{
                $t("trade.text67")
              }}</el-button>
            </div>
          </template>
        </el-table>
      </div>
    </div>
    <div>
      <el-dialog
        v-model="handCountVisible"
        destroy-on-close
        width="400px"
        :title="$t('trade.text63')"
        @closed="beforeClose"
      >
        <div class="close_position_confirm">
          <div>
            <div class="information_box">
              <div class="box-left">{{ $t("trade.text68") }}:</div>
              <div class="box-right">{{ activeItem.symbol }}</div>
            </div>
            <div class="information_box">
              <div class="box-left">{{ $t("trade.text69") }}:</div>
              <div class="box-right">{{ activeItem.quantity }}</div>
            </div>
            <div class="information_box">
              <div class="box-left">{{ $t("trade.text70") }}:</div>
              <div class="box-right">{{ handCount }}</div>
            </div>
            <div class="information_box">
              <div class="box-left">{{ $t("trade.text71") }}:</div>
              <div
                class="box-right"
                :class="
                  activeItem.profit
                    ? activeItem.profit.indexOf('-') == -1
                      ? 'up'
                      : 'down'
                    : ''
                "
              >
                {{
                  (
                    (handCount / activeItem.quantity) *
                    activeItem.profit
                  ).toFixed(2)
                }}
              </div>
            </div>
            <el-input
              @input="handCountInput"
              v-model="handCount"
              v-if="
                (instantTrading != 2 && instantTrading != undefined) ||
                closePosType == 2
              "
            >
              <template #suffix>
                <div class="fuhao jian cursor" @click="reduce('handCount')">
                  -
                </div>
                <div class="hr"></div>
                <div class="fuhao cursor" @click="add('handCount')">+</div>
              </template>
            </el-input>
            <div class="error_text" v-if="errorText">{{ errorText }}</div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handCountVisible = false">{{
              $t("trade.text72")
            }}</el-button>
            <el-button
              type="primary"
              :loading="loading"
              @click="closePosition"
              >{{ $t("trade.text73") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <div>
      <el-dialog
        v-model="orderDetailsVisible"
        destroy-on-close
        width="300px"
        :title="$t('trade.text75')"
      >
        <div class="order_details">
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text3") }}:</div>
            <div class="box-right">
              {{ closeData.symbol }}
              <img
                class="lable lable_red"
                :src="
                  closeData.bsType == '1'
                    ? $t('trade.text94')
                    : $t('trade.text95')
                "
              />
              <img
                class="lable lable_green"
                :src="
                  closeData.bsType == '1'
                    ? $t('trade.text94_green')
                    : $t('trade.text95_green')
                "
              />
              <img
                class="lable lable_blue"
                :src="
                  closeData.bsType == '1'
                    ? $t('trade.text94_blue')
                    : $t('trade.text95_blue')
                "
              />
            </div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text76") }}:</div>
            <div
              class="box-right"
              :class="closeData.profit.indexOf('-') == -1 ? 'up' : 'down'"
            >
              {{ closeData.profit }}
            </div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text70") }}:</div>
            <div class="box-right">{{ closeData.quantity }}</div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text53") }}:</div>
            <div class="box-right">{{ closeData.openPrice }}</div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text77") }}:</div>
            <div class="box-right">{{ closeData.closePrice }}</div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text78") }}:</div>
            <div class="box-right">{{ closeData.tradeComissionAmount }}</div>
          </div>
          <div class="information_box">
            <div class="box-left">{{ $t("trade.text79") }}:</div>
            <div class="box-right">{{ closeData.swap }}</div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              style="width: 100%"
              type="primary"
              @click="orderDetailsVisible = false"
              >{{ $t("trade.text73") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    <stop-profit-and-stopLoss
      :profit="activeItem.profit"
      :activeItem="activeItem"
    ></stop-profit-and-stopLoss>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  computed,
  inject,
  onBeforeUnmount,
  nextTick,
  watchEffect,
} from "vue";
import apiServer from "@/api/request";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import stopProfitAndStopLoss from "./stopProfitAndStopLoss";
import * as math from "mathjs";
import { ElMessageBox, ElMessage } from "element-plus";
import { close } from "fs";
import { useI18n } from "vue-i18n";

export default {
  name: "orderList",
  components: {
    stopProfitAndStopLoss,
  },
  props: {
    dataPosition: {
      type: Array,
      default: [],
    },
    tloading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { ctx } = getCurrentInstance();
    const { t } = useI18n();
    const emitt = inject("emitt");
    const store = useStore();
    const router = useRouter();
    const nowMainColor = computed({ get: () => store.state.home.mainColor });
    const beginDate = ref("");
    const endDate = ref("");
    const locale = reactive({
      emptyText: "",
    });
    const loading = ref(false);
    const handCount = ref(""); //平仓手数
    const handCountVisible = ref(false); //平仓手数弹框
    const userToken = computed({ get: () => store.getters.userToken });
    const errorText = ref(""); //平仓错误提示
    const closeData = reactive({});

    // 是否是交易员
    const isMasterAccount = computed({
      get: () => store.state.user.userStatus.isMasterAccount,
    });
    const instantTrading = ref();
    const closePosType = ref(1); //平仓方式：1.平仓 2.部分平仓

    //关闭弹框
    emitt.$sub("closehandCount", (val) => {
      handCountVisible.value = false;
    });
    onBeforeUnmount(() => {
      emitt.$unsub("closehandCount");
    });
    //手数校验
    function handCountCheck() {
      if (Number(handCount.value) < Number(minTradeSize)) {
        handCount.value = Number(minTradeSize);
      }
    }
    const orderDetailsVisible = ref(false); //平仓成功弹框
    //减
    function reduce() {
      errorText.value = "";
      if (Number(handCount.value) <= Number(minTradeSize)) {
        errorText.value = `${t("trade.text80")}${minTradeSize}${t(
          "trade.text81"
        )}`;
        return;
      }
      handCount.value = ensureAccuracy(
        Number(handCount.value) - Number(minTradeSize)
      );
    }
    //加
    function add() {
      errorText.value = "";
      if (Number(handCount.value) >= Number(maxTradeNum)) {
        errorText.value = `${t("trade.text82")}${maxTradeNum}${t(
          "trade.text81"
        )}`;
        return;
      }
      handCount.value = ensureAccuracy(
        Number(handCount.value) + Number(minTradeSize)
      );
    }
    //精度问题
    function ensureAccuracy(value) {
      const precision = 14;
      return Number(math.format(value, precision));
    }
    //确认平仓
    let serverFlag = false;
    function closePosition() {
      if (serverFlag) {
        return;
      }
      if (Number(handCount.value) == 0) {
        errorText.value = `${t("trade.text80")}${minTradeSize}${t(
          "trade.text81"
        )}`;
        handCountCheck();
        return;
      }
      let params = {
        usertoken: localStorage.userToken,
        posid: posId,
        quantity: handCount.value,
      };
      serverFlag = true;
      loading.value = true;
      apiServer.closePosDemo(params).then((res) => {
        serverFlag = false;
        if (res.status == 1) {
          handCountVisible.value = false;
          // closeData.tradeComissionAmount = '--';
          // closeData.text = '--';
          // Object.assign(closeData,res.data);
          // orderDetailsVisible.value = true;
          ElMessage({
            message: t("trade.text86"),
            type: "success",
          });
        } else {
          ElMessage({
            message: res.message,
            type: "error",
          });
        }
        loading.value = false;
      });
    }
    //手数校验
    function handCountInput(v) {
      errorText.value = "";
      let val = v;
      let regu = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      if (val[val.length - 1] != ".") {
        while (!regu.test(val) && val != "") {
          val = val.substring(0, val.length - 1);
        }
        // if(!regu.test(val)){

        // }
      } else {
        if (val.indexOf(".") != val.lastIndexOf(".")) {
          val = val.substring(0, val.length - 1);
        }
      }
      if (Number(val) > Number(maxTradeNum)) {
        val = maxTradeNum;
      }
      if (val.indexOf(".") != -1) {
        if (
          val.toString().split(".")[1].length >
          Number(minTradeSize).toString().split(".")[1].length
        ) {
          val = Number(val).toFixed(
            Number(minTradeSize).toString().split(".")[1].length
          );
        }
      }
      nextTick(() => {
        handCount.value = val;
      });
    }
    const nowIndex = ref(0);
    const activeItem = computed({
      get: () => {
        if (nowIndex.value + 1 <= props.dataPosition.length) {
          return props.dataPosition[nowIndex.value];
        } else {
          return {};
        }
      },
    });

    //全部平仓
    function closeAllPos() {
      ElMessageBox.confirm(t("trade.text83"), {
        confirmButtonText: t("trade.text73"),
        cancelButtonText: t("trade.text72"),
      })
        .then(() => {
          let params = {
            usertoken: localStorage.userToken,
          };
          apiServer.closeAllPosDemo(params).then((res) => {
            if (res.status == 1) {
              ElMessage({
                message: res.message,
                type: "success",
              });
            } else if (res.status == 406) {
              ElMessageBox.alert(res.message, {
                confirmButtonText: t("trade.text84"),
              })
                .then(() => {})
                .catch(() => {});
            } else {
              ElMessage({
                message: res.message,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    }
    //设置止盈止损
    function showStopProfitAndStopLoss(item) {
      console.log("props.dataPosition", props.dataPosition, item);
      for (let i in props.dataPosition) {
        if (props.dataPosition[i].posId == item.posId) {
          nowIndex.value = Number(i);
          break;
        }
      }
      emitt.$pub("showStopProfitAndStopLoss");
    }
    //一键反手
    function oneClickReversePos(e) {
      ElMessageBox.confirm(t("trade.text85"), {
        confirmButtonText: t("trade.text73"),
        cancelButtonText: t("trade.text72"),
      })
        .then(() => {
          let params = {
            usertoken: localStorage.userToken,
            posid: e.posId,
            quantity: e.quantity,
          };
          apiServer.oneClickReversePosDemo(params).then((res) => {
            if (res.status == 1) {
              ElMessage({
                message: t("trade.text86"),
                type: "success",
              });
            } else {
              ElMessage({
                message: res.message,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    }
    let maxTradeNum = ""; //最大交易手数
    let minTradeSize = ""; //最小交易手数
    let posId = ""; //平仓id
    //平仓
    function closePos(e, type) {
      closePosType.value = type;
      for (let i in props.dataPosition) {
        if (props.dataPosition[i].posId == e.posId) {
          nowIndex.value = Number(i);
          break;
        }
      }
      const params = {
        symbol: e.symbol,
      };
      closeData.symbol = e.symbol;
      closeData.bsType = e.bsType;
      apiServer.buyPageDemo(params).then((res) => {
        if (res.status == 1) {
          minTradeSize = res.data.minTradeSize;
          maxTradeNum = e.quantity;
          handCount.value = e.quantity;
          handCountVisible.value = true;
          posId = e.posId;
        }
      });
    }

    //平仓按钮
    function closePosAll(e) {
      instantTrading.value = localStorage.instantTrading;
      if (instantTrading.value == 1) {
        //快速交易
        posId = e.posId;
        handCount.value = e.quantity;
        closePosition();
      } else {
        closePos(e, 1);
      }
    }

    function disabledEndDate(endValue) {
      const startValue = beginDate.value;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    }
    function disabledStartDate(startValue) {
      const endValue = endDate.value;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    }

    function gotoMyAccount() {
      router.push("/accountInformation/Certification");
    }

    function goLogin() {
      router.push("/signUp");
    }

    function beforeClose() {
      errorText.value = "";
    }
    return {
      beginDate,
      endDate,
      locale,
      disabledStartDate,
      disabledEndDate,
      showStopProfitAndStopLoss,
      activeItem,
      oneClickReversePos,
      closePos,
      closeAllPos,
      handCountInput,
      handCountCheck,
      handCount,
      handCountVisible,
      reduce,
      add,
      closePosition,
      loading,
      gotoMyAccount,
      userToken,
      goLogin,
      errorText,
      orderDetailsVisible,
      closeData,
      beforeClose,
      instantTrading,
      closePosAll,
      closePosType,
      isMasterAccount,
    };
  },
};
</script>
<style lang="less" scoped>
.position_data {
  td {
    font-weight: bolder;
  }
  .locked {
    color: @primary-color;
  }
  .position_table {
    font-weight: bold;
  }
  .empty {
    margin-top: 100px; /*no*/
  }

  .order_list_title {
    .position {
      display: flex;
      padding: 0;
      color: @text-color-secondary;
      position: relative;
      .position_symbol {
        margin-right: 88px;
        .find_title {
          margin-right: 24px;
        }
      }

      .close_btn {
        position: absolute;
        right: 10px;
        top: -34px; /*no*/
        .close_all {
          padding: 3px 10px 4px 11px; /*no*/
          float: right;
          background: @primary-color;
          color: @white;
          border-radius: 2px; /*no*/
          font-size: 12px;
        }
      }
      .position_date {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        .date_title {
          margin-right: 24px;
        }
        .screening_picker {
          margin: 0 10px;
        }
      }
    }
    .no_login {
      margin-top: 100px;
      margin-bottom: 50px;
      img {
        display: block;
        margin: 0 auto;
      }
      .login_btn {
        margin-top: 24px;
        width: 168px;
      }
    }
  }
  .close_position_confirm {
    .information_box {
      font-size: 14px; /*no*/
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; /*no*/
      .box-right {
        width: 120px; /*no*/
        text-align: left;
        font-weight: 500;
      }
    }
    .fuhao {
      width: 26px; /*no*/
      color: @primary-color;
    }
    .jian {
      border-left: 1px solid @border-color-base; /*no*/
    }
    .hr {
      width: 1px; /*no*/
      height: 14px; /*no*/
      margin-top: 9px; /*no*/
      background: @border-color-base;
    }
  }
  .order_details {
    .information_box {
      font-size: 14px; /*no*/
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; /*no*/
      .box-right {
        width: 120px; /*no*/
        text-align: left;
        font-weight: 500;
      }
    }
    .lable {
      width: 24px; /*no*/
      display: inline-block;
      position: relative;
      top: 2px; /*no*/
      display: none;
    }
    .text {
      color: @primary-color;
      position: relative;
      padding-top: 10px; /*no*/
      word-break: break-word;
      font-size: 12px; /*no*/
    }
    .text::after {
      content: "";
      display: block;
      height: 1px; /*no*/
      background: @border-color-base;
      position: absolute;
      top: 0;
      width: 300px; /*no*/
      left: -32px; /*no*/
    }
  }
  .error_text {
    font-size: 12px; /*no*/
    color: @red;
  }
}
</style>
<style lang="less">
.position_data {
  thead {
    th {
      background: @bg-color !important;
      color: @text-color-secondary !important;
    }
  }
  .el-input__suffix {
    right: 0;
  }
  .operate {
    .el-button > span {
      text-decoration: underline !important;
    }
    .el-button {
      margin-left: 0;
      margin-right: 8px; /*no*/
    }
  }
}
</style>
