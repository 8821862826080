<template>
  <div class="trade-view1 k_line_mn" ref="trade">
    <div class="operation">
      <div class="symbol_name">
        <div style="font-weight: 600">
          {{ activeSymbol }}
        </div>
        <div
          class="symbol_price"
          :class="preData && preData.indexOf('-') != -1 ? 'down' : 'up'"
        >
          <div class="symbol_offerData">{{ offerData }}</div>
          <div>
            <span>{{ preValueData }}</span>
            <span class="pre">{{ preData }}</span>
          </div>
        </div>
        <div class="high">
          <div>{{ $t("trade.text11") }}</div>
          <div>{{ highData }}</div>
        </div>
        <div class="low">
          <div>{{ $t("trade.text12") }}</div>
          <div>{{ lowData }}</div>
        </div>
      </div>
      <div class="operation-time">
        <ul>
          <li
            :class="['cursor', item.name == nowInterval ? 'activeTime' : '']"
            v-for="(item, index) in arr"
            :key="item.title + index"
            @click="changeResolution(item.name)"
          >
            {{ item.title }}
          </li>
        </ul>
        <div class="operation_icon">
          <div class="span" @click="openFastTrad">
            {{ $t("trade.text147") }}
          </div>
          <i class="iconfont icon-trade_icon_indicators" @click="getPanes"></i>
          <i class="iconfont icon-trade_icon_set" @click="openChartSetting"></i>
          <i
            class="iconfont icon-icon_figure_fullscreen"
            @click="fullScreen($event)"
          ></i>
        </div>
      </div>
    </div>
    <div id="trade-view" ref="tradeView"></div>
    <div class="transaction_bottom_deal" v-show="fastTrad">
      <business></business>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
  getCurrentInstance,
  onBeforeUnmount,
  inject,
} from "vue";
import { useStore } from "vuex";
// import { get } from 'jquery';
import apiServer from "@/api/request";
import socket from "./datafeeds/socket.js";
// import datafeeds from "./datafeeds/datafees.js";
import { useI18n } from "vue-i18n";
import business from "./businessDemo";

import { widget } from "../../../../../static/tradeview/charting_library/charting_library.js";
const TradingView = { widget }

export default {
  name: "KLineMn",
  components: {
    business,
  },
  setup(props) {
    const emitt = inject("emitt");
    const store = useStore();
    // const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    // console.log("proxy", proxy);
    // const datafeed = new datafeeds(proxy);
    const offerData = ref("");
    const preValueData = ref("");
    const preData = ref("");
    const highData = ref("");
    const lowData = ref("");
    const fastTrad = ref(true);
    /* 打开快速交易框-开始
     */
    const subOpenFastTradeBox = (val) => {
      fastTrad.value = !fastTrad.value;
    }
    emitt.$sub("openFastTradBox", subOpenFastTradeBox);

    const lang = computed({ get: () => store.getters.getNowTheme.lang });

    const updateSymbolQuote = (val) => {
      const {
        offerDataPass,
        preValueDataPass,
        preDataPass,
        highDataPass,
        lowDataPass,
      } = val[0];
      offerData.value = offerDataPass;
      preValueData.value = preValueDataPass;
      preData.value = preDataPass;
      highData.value = highDataPass;
      lowData.value = lowDataPass;
    }
    let updateKlineBar = (val) => {
      if (subscribeList) {
        subscribeList.forEach(item => item.tickUpdater(val))
      }
    }

    const subPassByValue = (val) => {
      updateSymbolQuote(val)
      updateKlineBar(val)
    }

    emitt.$sub("passByValue", subPassByValue);
    onBeforeUnmount(() => {
      if (socketLink) {
        socketLink.close();
        socketLink = null;
      }
      emitt.$unsub("passByValue", subPassByValue);
      emitt.$unsub("openFastTradBox", subOpenFastTradeBox);
    });
    // 主题色
    const nowMainColor = computed({ get: () => store.state.home.mainColor });
    const activeSymbol = computed({
      get: () => store.state.home.activeSymbolItem.symbol,
    });
    const contentColor = computed({ get: () => store.state.home.contentColor });
    // websocket地址
    const qouteWsUrl = computed({ get: () => store.state.trade.qouteWsUrl });
    // let nowMainColor = "";//当前主题色
    const upColor = computed({ get: () => store.state.home.contentColor }); // 当前涨跌色
    let beforeInterval = ""; // 上一个时间
    let Charttype = ""; //
    let beforeSymbol = ""; // 上一个合约
    const symbolWai = ref(""); //
    const nowInterval = ref("240");

    const cacheData = {};
    let lastTime = null;
    let isLoading = true;
    const arr = [
      { name: "1", title: t("trade.text135"), chartType: 1 },
      { name: "5", title: t("trade.text136"), chartType: 1 },
      { name: "15", title: t("trade.text137"), chartType: 1 },
      { name: "30", title: t("trade.text138"), chartType: 1 },
      { name: "60", title: t("trade.text139"), chartType: 1 },
      { name: "240", title: t("trade.text140"), chartType: 1 },
      { name: "1D", title: t("trade.text141"), chartType: 1 },
      { name: "1W", title: t("trade.text142"), chartType: 1 },
      { name: "1M", title: t("trade.text143"), chartType: 1 },
    ];
    let socketLink = null;
    // 监听主题色
    watch(nowMainColor, (mainColor, prevMinColor) => {
      nowMainColor.value = mainColor;
      if (mainColor && mainColor !== prevMinColor) {
        if (widget) {
          // 修改K线图颜色
          // widget.applyOverrides(getOverrides(mainColor, upColor));
          // widget.applyStudiesOverrides(getStudiesOberrides(upColor));
          // widget.addCustomCSSFile(`/static/tradeview/${mainColor}.css`);
          // createStudy();
        }
      }
    });
    // 监听涨跌色
    watch(upColor, (upColor, prevUpColor) => {
      if (upColor && upColor !== prevUpColor) {
        if (widget) {
          // 修改K线图颜色
          // widget.applyStudiesOverrides(getStudiesOberrides(upColor));
          // widget.applyOverrides(getOverrides(nowMainColor.value, upColor));
          // // widget.chart().applyStudiesOverrides(getStudiesOberrides(upColor))
          // console.log(widget._innerWindow(),widget.applyStudiesOverrides)
          // console.log(widget.chart())
        }
      }
    });
    // 监听当前合约修改
    watch(activeSymbol, (activeSymbol, prevActiveSymbol) => {
      if (activeSymbol && activeSymbol !== prevActiveSymbol) {
        console.log("nowInterval.value11111s");
        // nowInterval.value = '1';
        Charttype = 1;
        // beforeSymbol = activeSymbol;
        // changeTradeView(nowInterval.value);
        // load();
        changeSymbol(activeSymbol, nowInterval.value)
      }
    });

    let widget = null;
    let currentMarket = {
      symbolname: "",
      symbol: "",
      lasttradingdate: "",
      tradestatustext: "",
      nowv: "",
      // updown: '123',
      // updownrate: '33',
      high: "",
      open: "",
      lowp: "",
      preclose: "",
      fluctuate: "",
      changespeed: "",
      timedata: [],
      qtydecimalplace: 1,
      tradePrice: "",
    };
    const symbolInfo = {};

    const configurationData = {
      supports_search: true,
      supports_group_request: false,
      supported_resolutions: [
        '1',
        '3',
        '5',
        '15',
        '30',
        '60',
        '240',
        '1D',
        '1W',
        '1M',
      ],
      supports_marks: true,
      supports_timescale_marks: true,
      supports_time: true,
    }

    const defaultSymbol = {
      name: 'USDJPY',
      timezone: 'Asia/Shanghai',
      minmov: 1,
      minmov2: 0,
      pointvalue: 1,
      fractional: false,
      has_intraday: true,
      // has_no_volume: false,
      visible_plots_set: 'ohlcv',
      has_weekly_and_monthly: true,
      session: '24x7',
      pricescale: 100000,
      ticker: 'USDJPY',
      supported_resolutions: [
        '1',
        '3',
        '5',
        '15',
        '30',
        '60',
        '240',
        '1D',
        '1W',
        '1M',
      ],
    }

    let subscribeList = []

    const subscribeQuote = (symbolInfo,
        resolution,
        onTick,
        listenerGuid,
        // onResetCacheNeededCallback
      ) => {

        const unsubIndex = subscribeList.findIndex(item => item.key === listenerGuid)
        if (unsubIndex > -1) {
          subscribeList = [
            ...subscribeList.slice(0, unsubIndex),
            ...subscribeList.slice(unsubIndex)
          ]
        }

        let barsData;
        // console.log('subs', symbolInfo, resolution, onTick, listenerGuid)
        const klineSocket = initSocket2(symbolInfo.ticker, formateInterval(resolution), (data) => {
          // console.log(symbolInfo.ticker, activeSymbol.value)
          if (!barsData || data.timestamp > barsData.timestamp) {
            // console.log('kline', data.timestamp, data.close)
            barsData = {
              time: data.time * 1,
              open: parseFloat(data.open),
              high: parseFloat(data.high),
              low: parseFloat(data.low),
              close: parseFloat(data.close),
              volume: data.volume,
              preClose: data.preClose,
              timestamp: data.timestamp
            }
            // console.log('update kline', barsData.close)
            onTick({
              ...barsData
            })
          }
        })

        const tickUpdater = (val) => {
          const {
            symbol,
            // offerDataPass,
            askPrice,
            timestamp
          } = val[0]
          // console.log('tick', timestamp, askPrice)
          if (barsData && symbol === symbolInfo.ticker && timestamp > barsData.timestamp && +askPrice !== +barsData.close) {
            barsData.close = askPrice
            barsData.timestamp = timestamp
            if (barsData.close > barsData.high) {
              barsData.high = barsData.close
            }
            if (barsData.close < barsData.low) {
              barsData.low = barsData.close
            }
            // console.log('update tick', barsData.close)
            onTick({
              ...barsData
            })
          }
        }

      subscribeList.push({key: listenerGuid, barsData, klineSocket, tickUpdater})

    }

    const unsubscribeQuote = (listenerGuid) => {
      // console.log('unsubscribe: ' + listenerGuid)
      const unsubIndex = subscribeList.findIndex(item => item.key === listenerGuid)
      if (unsubIndex > -1) {
        subscribeList[unsubIndex].klineSocket.close()
        subscribeList = [
          ...subscribeList.slice(0, unsubIndex),
          ...subscribeList.slice(unsubIndex)
        ]
      }
    }

    const datafeed = {
      onReady: (callback) => {
        callback(configurationData)
      },
      //   searchSymbols: (userInput,
      // exchange,
      // symbolType,
      // onResultReadyCallback,) => {

      //   },
      resolveSymbol: (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
      ) => {
        setTimeout(() => {
          const conf = {
            ...defaultSymbol,
            ...getSymbol(),
          }
          onSymbolResolvedCallback(conf)
        }, 1)
      },
      getBars: async (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        // onErrorCallback
      ) => {
        // console.log('getBars', symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback)
        const data = await getCandelLinesHandler2(
          symbolInfo.ticker,
          formateInterval(resolution),
          3,
          periodParams.countBack,
          periodParams.to * 1000
        )

        if (data !== undefined && data.lineData.length > 0) {
          const candleData = data.lineData.map((item) => {
            return {
              time: item.startTime * 1,
              open: parseFloat(item.open),
              high: parseFloat(item.high),
              low: parseFloat(item.low),
              close: parseFloat(item.close),
              volume: item.vol,
            }
          })
          onHistoryCallback(candleData)
        } else {
          onHistoryCallback([], { noData: true })
        }
      },
      subscribeBars: (
        symbolInfo,
        resolution,
        onTick,
        listenerGuid,
        onResetCacheNeededCallback
      ) => {
        subscribeQuote(symbolInfo,
        resolution,
        onTick,
        listenerGuid,
        onResetCacheNeededCallback)
        // let barsData;
        // // console.log('subs', symbolInfo, resolution, onTick, listenerGuid)
        // initSocket2(symbolInfo.ticker, formateInterval(resolution), (data) => {
        //   if (!barsData || data.timestamp > barsData.timestamp) {
        //     barsData = {
        //       time: data.time * 1,
        //       open: parseFloat(data.open),
        //       high: parseFloat(data.high),
        //       low: parseFloat(data.low),
        //       close: parseFloat(data.close),
        //       volume: data.volume,
        //       preClose: data.preClose,
        //       timestamp: data.timestamp
        //     }
        //     onTick({
        //       ...barsData
        //     })
        //   }
        // })

        // updateKlineBar = (val) => {
        //   const {
        //     symbol,
        //     askPrice,
        //     timestamp
        //   } = val[0]
        //   if (barsData && symbol === symbolInfo.ticker && timestamp > barsData.timestamp && +askPrice !== +barsData.close) {
        //     barsData.close = askPrice
        //     barsData.timestamp = timestamp
        //     if (barsData.close > barsData.high) {
        //       barsData.high = barsData.close
        //     }
        //     if (barsData.close < barsData.low) {
        //       barsData.low = barsData.close
        //     }
        //     onTick({
        //       ...barsData
        //     })
        //   }
        // }

      },
      unsubscribeBars: (listenerGuid) => {
        // console.log('unsub', listenerGuid)
        unsubscribeQuote(listenerGuid)
      },
    }
    // 初始化k线
    function init(
      symbol = activeSymbol.value || "USDJPY",

      interval = 1,
      chartType = 1
    ) {
      if (localStorage.nowInterval) {
        interval = localStorage.nowInterval;
      }
      // tradingview初始化
      if (!widget) {
        // console.log("datafeed", datafeed);
        const overrides = getOverrides(nowMainColor.value, contentColor.value)
        const study_overrides = getStudiesOberrides(contentColor.value)
        widget = new TradingView.widget({
          symbol, // 产品
          interval, // 周期
          auto_save_delay: 1,
          // fullscreen: true,//是否全屏显示
          container: "trade-view", // 初始化元素id
          // debug: true,
          datafeed,
          library_path: "/static/tradeview/charting_library/", // static文件夹路径
          custom_css_url: `/static/tradeview/${nowMainColor.value}.css`,
          enabled_features: [
            "left_toolbar",
            "edit_buttons_in_legend",
            "side_toolbar_in_fullscreen_mode",
          ],
          timezone: "Asia/Shanghai",
          supported_resolutions: [
            "1",
            "5",
            "15",
            "30",
            "60",
            "240",
            "1D",
            "1W",
            "1M",
          ],
          locale: lang.value == "cn" ? "zh" : "en",
          debug: false, // 是否在控制台显示api日志
          toolbar_bg: "",
          pricescale: 10000000,
          disabled_features: [
            // 默认禁用属性
            "header_widget",
            "volume_force_overlay",
            "go_to_date",
            "header_widget_dom_node",
            "header_compare",
            "header_indicators",
            "header_chart_type",
            "header_resolutions",
            "header_screenshot",
            "header_settings",
            "header_symbol_search",
            "header_undo_redo",
            "adaptive_logo",
            "show_logo_on_all_charts",
            "display_market_status",
            "control_bar",
            "page_contest_menu",
            "timeframes_toolbar",
            "chart_property_page_background",
            "items_favoriting",
            "lock_visible_time_range_on_resize",
            "support_multicharts",
            "symbol_info",
            "timezone_menu",
            "charting_library_debug_mode",
          ],
          overrides: {
            ...overrides
            
          }, // getOverrides(nowMainColor.value, contentColor.value),
          studies_overrides: {
            ...study_overrides
          },// getStudiesOberrides(contentColor.value),
          header_fullscreeen_button: "on",
          items_favoriting: "off",
          width: "100%",
          height: "100%",
          autosize: true,
          loading_screen: {
            backgroundColor:
              nowMainColor.value === "black" ? "#212845" : "#ffffff",
          },
        });
        beforeSymbol = symbolWai.value = activeSymbol.value;
        beforeInterval = nowInterval.value = interval;
        // Charttype = 1;
        widget.onChartReady(() => {
          widget.subscribe("onAutoSaveNeeded", function () {
            save();
          });
          load();
          widget.applyOverrides({ ...overrides });
          widget.applyStudiesOverrides({
              ...study_overrides
          });
          // 修改type且增加button
          // widget.activeChart().setChartType(Charttype);
          // createStudy();
          // if(localStorage.nowInterval){
          //   changeTradeView(localStorage.nowInterval);
          // }
        });
        // initSocket();
      }
    }

    function fun3(obj) {
      console.log(obj);
      localStorage.obj = JSON.stringify(obj);
    }

    function save() {
      widget.save(fun3);
    }

    function load() {
      if (localStorage.obj) {
        widget.load(JSON.parse(localStorage.obj));
        // changeTradeView(nowInterval.value);
        changeResolution(nowInterval.value)
      }
    }

    function beignWidgetFn(callBack) {
      // 调用widget方法
      widget.onChartReady(callBack);
    }
    function getOverrides(theme = "black", up = "red") {
      const themes = {
        black: {
          bg: "#30364C", // 背景色
          scanLine: "#1B1F35", // 边界颜色
          scanText: "#6D87A8", // 边界字体颜色
          // --面积图色
          mainAreaColor2: "rgba(90,33,200,0)",
          mainAreaLine: "#9862FF",
        },
        white: {
          bg: "#ffffff", // 背景色
          scanLine: "#C5CFD5", // 边界颜色
          scanText: "#656B74", // 边界字体颜色
          // --面积图色
          mainAreaColor2: "rgba(251, 91, 57,0)",
          mainAreaLine: "#FB5B39",
        },
        red: {
          up: "#FB5B39",
          down: "#16BB94",
        },
        green: {
          up: "#16BB94",
          down: "#FB5B39",
        },
        blue: {
          up: "#1B67FF",
          down: "#FB5B39",
        },
      };
      const t = themes[theme];
      const u = themes[up];
      return {
        // 对默认属性进行覆盖
        "paneProperties.legendProperties.showLegend": true,
        "paneProperties.background": t.bg,
        "paneProperties.vertGridProperties.color": "#E6E6E6",
        "paneProperties.horzGridProperties.color": "#E6E6E6",
        "paneProperties.topMargin": "15",
        "paneProperties.bottomMargin": "4",
        // 'symbolWatermarkProperties.color': 'rgba(0, 0, 0, 0)', // 使水印不可见
        // 'paneProperties.crossHairProperties.width': '10',//鼠标移入时交叉线的宽度
        // 'mainSeriesProperties.priceLineWidth': '20',//价格线宽度
        // 'mainSeriesProperties.haStyle.linewidth': '100',//价格线宽度
        // -----------volume的大小large,medium,small,tiny
        volumePaneSize: "small",
        // 边界颜色
        "scalesProperties.lineColor": t.scanLine,
        "scalesProperties.textColor": t.scanText,
        // "mainSeriesProperties.candleStyle.drawBorder": true,
        // -----------面积图，分时图的颜色
        // 'mainSeriesProperties.areaStyle.color1': 'rgba(62,108,174,.5)', // "#3278DD",
        "mainSeriesProperties.areaStyle.color2": t.mainAreaColor2, // "#3278DD",
        // "mainSeriesProperties.areaStyle.color3": "rgba(62,108,174,0)",
        "mainSeriesProperties.areaStyle.linecolor": t.mainAreaLine,
        //-------------
        // 'mainSeriesProperties.lineStyle.color': '#fff',
        "mainSeriesProperties.lineStyle.linestyle": 0,
        "mainSeriesProperties.candleStyle.upColor": u.up,
        "mainSeriesProperties.candleStyle.downColor": u.down,
        "mainSeriesProperties.candleStyle.borderUpColor": u.up,
        "mainSeriesProperties.candleStyle.borderDownColor": u.down,
        "mainSeriesProperties.candleStyle.wickUpColor": u.up,
        "mainSeriesProperties.candleStyle.wickDownColor": u.down,
        "mainSeriesProperties.hiloStyle.showBorders": false,
        "mainSeriesProperties.candleStyle.drawBorder": false,
        "volume.volume.color.0": u.down,
        "volume.volume.color.1": u.up,
        "volume.show ma": false,
        "volume.volume.transparency": 0,
        "volume.options.showStudyArguments": !1,
      };
    }
    // 获取studies_oversides
    function getStudiesOberrides(up = "red") {
      const themes = {
        red: {
          up: "#FB5B39",
          down: "#16BB94",
        },
        green: {
          up: "#16BB94",
          down: "#FB5B39",
        },
        blue: {
          up: "#1B67FF",
          down: "#FB5B39",
        },
      };
      const v = themes[up];
      return {
        "volume.volume.color.0": v.down,
        "volume.volume.color.1": v.up,
        "volume.show ma": false,
        "volume.volume.transparency": 0,
        "volume.options.showStudyArguments": !1,
      };
    }
    let studies = [];
    function createStudy() {
      // 获取均线种类和样式
      let color1 = "";
      let color2 = "";
      let color3 = "";
      widget.activeChart().removeEntity(studies[0]);
      widget.activeChart().removeEntity(studies[1]);
      widget.activeChart().removeEntity(studies[2]);
      studies = [];
      if (nowMainColor.value == "white") {
        color1 = "#0055FE";
        color2 = "#BF16FF";
        color3 = "#16BB94";
      } else {
        color1 = "#FF4B4B";
        color2 = "#FFD657";
        color3 = "#06D2FF";
      }
      let id = widget
        .chart()
        .createStudy("Moving Average", false, false, [5], null, {
          "Plot.color": color3,
        });
      studies.push(id);
      id = widget
        .chart()
        .createStudy("Moving Average", false, false, [10], null, {
          "Plot.color": color2,
        });
      studies.push(id);
      id = widget
        .chart()
        .createStudy("Moving Average", false, false, [30], null, {
          "plot.color": color1,
        });
      studies.push(id);
    }

    function changeResolution(resolution) {
      localStorage.nowInterval = resolution

      nowInterval.value = resolution
      beforeInterval = nowInterval.value

      const activeChart = widget.activeChart()
      activeChart.setResolution(resolution)

      const rightAxis = activeChart.getPanes()[0].getRightPriceScales()[0]
      rightAxis.setAutoScale(true)
      // rightAxis.setVisiblePriceRange(null)
    }

    function changeSymbol(symbol, resolution) {
      widget.setSymbol(
        symbol,
        resolution,
        () => {
          // console.log('change symbol from ' + beforeSymbol + ' to ' + symbol)
          beforeSymbol = symbol
          const rightAxis = widget.activeChart().getPanes()[0].getRightPriceScales()[0]
          rightAxis.setAutoScale(true)
        }
      )
    }

    // function changeTradeView(name) {
    //   localStorage.nowInterval = name;

    //   // 切换k线图展示
    //   if (beforeInterval || beforeSymbol) {
    //     unsubscribeBars(`${beforeSymbol}_${beforeInterval}`);
    //     beforeSymbol = activeSymbol.value;
    //   }
    //   console.log("nowInterval.value2222s");

    //   nowInterval.value = name;

    //   beforeInterval = nowInterval.value;
    //   const params = nowInterval.value;
    //   const ticker = `${activeSymbol.value}_${nowInterval.value}`;
    //   if (socketLink) {
    //     socketLink.close();
    //     socketLink = null;
    //   }
    //   widget.onChartReady(() => {
    //     widget.setSymbol(
    //       activeSymbol.value,
    //       params.toUpperCase() || params,
    //       () => {}
    //     );
    //     widget
    //       .activeChart()
    //       .onSymbolChanged()
    //       .subscribe(null, () => console.log("The symbol is changed"));
    //   });
    //   initSocket();
    // }

    // function changeTradeView2(name) {
    //   if (name == localStorage.nowInterval) return;

    //   localStorage.nowInterval = name;

    //   // 切换k线图展示
    //   if (beforeInterval || beforeSymbol) {
    //     unsubscribeBars(`${beforeSymbol}_${beforeInterval}`);
    //     beforeSymbol = activeSymbol.value;
    //   }
    //   console.log("nowInterval.value2222");

    //   nowInterval.value = name;

    //   beforeInterval = nowInterval.value;
    //   const params = nowInterval.value;
    //   const ticker = `${activeSymbol.value}_${nowInterval.value}`;
    //   if (socketLink) {
    //     socketLink.close();
    //     socketLink = null;
    //   }
    //   widget.activeChart().setResolution(params);
    //   // widget.onChartReady(() => {
    //   //   // widget.setSymbol(
    //   //   //   activeSymbol.value,
    //   //   //   params.toUpperCase() || params,
    //   //   //   (e) => {}
    //   //   // );
    //   //   // widget
    //   //   //   .activeChart()
    //   //   //   .onSymbolChanged()
    //   //   //   .subscribe(null, (e) => console.log("changeTradeViewFlag555", e));

    //   // });
    //   initSocket();
    // }

    function setChartType() {
      // 设置图表的格式并增加button
      beignWidgetFn(() => {
        widget.chart().setChartType(Charttype);
      });
    }
    // 初始化websocket
    // function initSocket(obj) {
    //   if (!obj) obj = {};
    //   const paramSymbol = obj.symbol || activeSymbol.value;
    //   const paramInterval = obj.interval || nowInterval.value;

    //   if (socketLink) {
    //     socketLink.close();
    //     socketLink = null;
    //   }
    //   const url =
    //     qouteWsUrl.value +
    //     `/kline?symbol=${paramSymbol}&interval=${formateInterval(
    //       paramInterval
    //     )}&priceType=3`;
    //   socketLink = new socket(url);
    //   socketLink.on("message", onMessage);
    // }

    function initSocket2(paramSymbol, paramInterval, callback) {
      // if (!obj) obj = {}
      // const paramSymbol = obj.symbol || activeSymbol.value
      // const paramInterval = obj.interval || nowInterval.value

      if (socketLink) {
        socketLink.close()
        socketLink = null
      }
      const url =
        qouteWsUrl.value +
        `/kline?symbol=${paramSymbol}&interval=${formateInterval(
          paramInterval
        )}&priceType=3`
      socketLink = new socket(url)
      socketLink.on('message', callback)
    }

    function initMessage(
      symbolInfo,
      resolution,
      rangeStartDate,
      rangeEndDate,
      onLoadedCallback
    ) {
      // console.log('发起请求，从http获取当前时间段的数据');
      // 保留当前回调
      const tickerCallback = `${activeSymbol.value}_${resolution}Callback`;
      cacheData[tickerCallback] = onLoadedCallback;
      // 获取当前时间段的数据，在onMessage中执行回调onLoadedCallback
      getCandelLinesHandler({ count: 300 });
    }

    function getCandelLinesHandler2(
      symbol,
      interval,
      priceType,
      count,
      endtime
    ) {
      // 获取历史数据
      const params = {
        symbol,
        interval,
        priceType,
        count,
        endtime,
      }

      return apiServer.candleLines(params).then((res) => {
        if (+res.status === 1) {
          return res.data
        } else {
          return undefined
        }
      })
    }

    function getCandelLinesHandler(obj = {}) {
      // 获取历史数据
      const params = {
        symbol: obj.symbol || activeSymbol.value,
        interval: formateInterval(nowInterval.value),
        priceType: 3,
        count: obj.count || 150,
      };
      if (obj.endDate) Object.assign(params, { endtime: obj.endDate * 1000 });
      return apiServer.candleLines(params).then((res) => {
        if (res.status == "1") {
          onMessage(res.data.lineData);
          currentMarket = res.data;
        }
      });
    }
    // 打开指标窗口
    function getPanes() {
      beignWidgetFn(() => {
        widget.chart().executeActionById("insertIndicator");
      });
    }

    function openChartSetting() {
      // 打开设置窗口
      const _this = this;
      beignWidgetFn(() => {
        widget.chart().executeActionById("chartProperties");
      });
    }
    let isFullscreen = true;
    function fullScreen() {
      // 全屏
      isFullscreen = !isFullscreen;
      const el = document.getElementsByClassName("trade-view1")[0];
      openFullScreen(el);
    }
    function openFullScreen(el) {
      // 开启全屏或关闭
      if (isFullscreen) {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // 改变平面图在google浏览器上面的样式问题
          el.style.position = "static";
          el.style.width = "100%";
          document.webkitExitFullscreen();
        } else if (!document.msRequestFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 进入全屏
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) {
          // 改变平面图在google浏览器上面的样式问题
          el.style.position = "static";
          el.style.width = "100%";
          el.style.height = "1000px";
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen();
        }
      }
    }
    // 获取K线数据
    function onMessage(data) {
      if (
        Object.prototype.toString.call(data) === "[object Array]" &&
        data.length !== 0
      ) {
        // 请求https数据进行处理
        const list = [];
        const ticker = `${activeSymbol.value}_${nowInterval.value}`;
        const tickerstate = `${ticker}state`;
        const tickerCallback = `${ticker}Callback`;
        const tickerload = `${ticker}load`;
        const onLoadedCallback = cacheData[tickerCallback];
        data.forEach((element) => {
          list.push({
            time: element.startTime * 1,
            open: parseFloat(element.open),
            high: parseFloat(element.high),
            low: parseFloat(element.low),
            close: parseFloat(element.close),
            volume: element.vol,
          });
        });
        if (!cacheData[ticker]) {
          // 如果没有缓存数据则直接填充
          cacheData[ticker] = list;
          cacheData[tickerload] = (parseInt(list[0].time) / 1000).toFixed(0);
        }
        if (onLoadedCallback) {
          // 如果存在数据则直接填充
          if (list.length <= 150) {
            cacheData[ticker] = cacheData[ticker].concat(list);
          }
          cacheData[tickerload] = (parseInt(list[0].time) / 1000).toFixed(0);
          onLoadedCallback(list);
          delete cacheData[tickerCallback];
        }
        // 请求完成，设置状态为false
        cacheData[tickerstate] = !1;
        console.log("cacheData", cacheData, ticker, cacheData[ticker]);
        // 记录当前缓存时间，即数组最后一位的时间
        lastTime = cacheData[ticker][cacheData[ticker].length - 1].time;
      }
      if (
        Object.prototype.toString.call(data) === "[object Array]" &&
        data.length === 0
      ) {
        // 请求完成，设置状态为false
        const tickerstate = `${activeSymbol.value}_${nowInterval.value}state`;
        const onLoadedCallback =
          cacheData[`${activeSymbol.value}_${nowInterval.value}Callback`];
        if (onLoadedCallback) {
          onLoadedCallback([]);
        }
        cacheData[tickerstate] = !1;
      }
      if (data.symbol && data.symbol === activeSymbol.value) {
        const ticker = `${activeSymbol.value}_${nowInterval.value}`;
        const barsData = {
          time: data.time * 1,
          open: parseFloat(data.open),
          high: parseFloat(data.high),
          low: parseFloat(data.low),
          close: parseFloat(data.close),
          volume: data.volume,
          preClose: data.preClose,
        };
        currentMarket.low = barsData.low;
        currentMarket.high = barsData.high;
        currentMarket.prevClose = barsData.preClose;

        if (
          barsData.time >= lastTime &&
          cacheData[ticker] &&
          cacheData[ticker].length
        ) {
          if (
            parseInt((barsData.time / 1000).toString()) ===
            parseInt((lastTime / 1000).toString())
          ) {
            cacheData[ticker][cacheData[ticker].length - 1] = barsData;
          } else {
            cacheData[ticker].push(barsData);
            lastTime = barsData.time;
          }
        }
        datafeed.barsUpdater.updateData();
      }
    }

    function getCandelLinesHandler(obj = {}) {
      // 获取历史数据
      const params = {
        symbol: obj.symbol || activeSymbol.value,
        interval: formateInterval(nowInterval.value),
        priceType: 3,
        count: obj.count || 150,
      };
      if (obj.endDate) Object.assign(params, { endtime: obj.endDate * 1000 });
      return apiServer.candleLines(params).then((res) => {
        if (res.status == "1") {
          onMessage(res.data.lineData);
          currentMarket = res.data;
        }
      });
    }
    function formateInterval(date) {
      let str = date
      if (typeof date === 'string' && date.toUpperCase().indexOf('D') != -1) {
        str = '1d'
      } else if (typeof date === 'string' && date.toUpperCase().indexOf('W') != -1) {
        str = '1w'
      } else if (typeof date === 'string' && date.indexOf('M') != -1) {
        str = '1M'
      } else if (typeof date === 'string' && date.indexOf('h') != -1) {
        str = date
      }  else if (Math.floor((date * 1) / 60) >= 1) {
        str = `${Math.floor((date * 1) / 60)}h`
      } else if (String(date).indexOf('m') === -1) {
        str = `${date}m`
      }
      return str
    }
    function getSymbol() {
      // 当前商品详情
      console.log("activeSymbol.value,222", store.state.home.activeSymbolItem);
      return {
        name: activeSymbol.value,
        ticker: `${activeSymbol.value}`,
        pricescale: Math.pow(
          10,
          Number(store.state.home.activeSymbolItem.decimalPlace)
        ),
      };
    }
    function unsubscribeBars(ticker) {
      // 取消订阅当前K线
      const tickerstate = `${ticker}state`;
      const tickerCallback = `${ticker}Callback`;
      const tickerload = `${ticker}load`;
      delete cacheData[tickerload];
      delete cacheData[ticker];
      delete cacheData[tickerstate];
      delete cacheData[tickerCallback];
      datafeed.unsubscribeBars(cacheData);
      lastTime = null;
    }

    async function getBars(
      symbolInfo,
      resolution,
      rangeStartDate,
      rangeEndDate,
      onLoadedCallback
    ) {
      let ticker = `${activeSymbol.value}_${resolution}`;
      const tickerload = `${ticker}load`;
      const tickerstate = `${ticker}state`;
      const tickerCallback = `${ticker}Callback`;
      const { interval } = widget.symbolInterval();
      if (interval !== resolution) {
        if (datafeed.barsUpdater.subscribers[ticker]) {
          datafeed.barsUpdater.subscribers[
            `${activeSymbol.value}_${interval}`
          ] = {
            ...datafeed.barsUpdater.subscribers[ticker],
            resolution: interval,
          };
        }
        unsubscribeBars(ticker);
        datafeed.getBars(
          symbolInfo,
          interval,
          rangeStartDate,
          rangeEndDate,
          onLoadedCallback
        );
        return;
      }

      if (!cacheData[ticker] && !cacheData[tickerstate]) {
        // console.log('1 tickerData', ticker, symbolInfo, resolution, rangeStartDate, rangeEndDate);
        // 如果缓存没有数据，而且未发出请求，记录当前节点开始时间
        // this.cacheData[tickerload] = rangeStartDate;
        // 发起请求，从http获取当前时间段的数据
        initMessage(
          symbolInfo,
          resolution,
          rangeStartDate,
          rangeEndDate,
          onLoadedCallback
        );
        // 设置状态为true
        cacheData[tickerstate] = !0;
        return false;
      }
      if (!cacheData[tickerload] || cacheData[tickerload] > rangeStartDate) {
        // 如果缓存有数据，但是没有当前时间段的数据，更新当前节点时间
        cacheData[tickerCallback] = onLoadedCallback;
        // 发起请求，从http获取当前时间段的数据
        await getCandelLinesHandler({ endDate: cacheData[tickerload] });
        // 设置状态为true
        cacheData[tickerstate] = !0;
        return false;
      }
      // if(this.cacheData[tickerstate]){
      //     //正在从websocket获取数据，禁止一切操作
      //     return false;
      // }
      ticker = `${activeSymbol.value}_${nowInterval.value}`;
      if (cacheData[ticker] && cacheData[ticker].length) {
        isLoading = false;
        const newBars = [];
        cacheData[ticker].forEach((item) => {
          if (
            parseInt((item.time / 1000).toString()) >= rangeStartDate &&
            parseInt((item.time / 1000).toString()) <= rangeEndDate
          ) {
            newBars.push(item);
          }
        });
        onLoadedCallback(newBars);
      } else {
        // await this.getCandelLinesHandler();
        getBars(
          symbolInfo,
          resolution,
          rangeStartDate,
          rangeEndDate,
          onLoadedCallback
        );
      }
    }

    function openFastTrad() {
      emitt.$pub("openFastTradBox");
    }

    onMounted(() => {
      init();
    });
    return {
      nowMainColor,
      getBars,
      activeSymbol,
      arr,
      // changeTradeView,
      changeResolution,
      getPanes,
      openChartSetting,
      fullScreen,
      getSymbol,
      nowInterval,
      offerData,
      preValueData,
      preData,
      highData,
      lowData,
      save,
      load,
      openFastTrad,
      fastTrad,
      // changeTradeView2,
    };
  },
};
</script>
<style lang="less">
.trade-view1 {
  min-height: 100px;
  /*no*/
  /*no*/
  background: @white;
  overflow: hidden;

  .trade-tab-div {
    width: 45%;
    float: left;

    .trade-tab {
      justify-content: space-between;
      border: none;
      font-size: 14px;

      .tab-item {
        margin: 0 1.1538%;
        padding: 0;
      }
    }
  }
}

.k_line_mn {
  height: 100%;

  .operation {
    line-height: 29px;
    text-align: left;
    border-bottom: 1px solid #f4f5f6;

    ul {
      display: flex;
      justify-content: space-between;
      width: 480px;
      font-size: 14px;
      color: @black;
      align-items: center;
      height: 30px;
      /*no*/
      line-height: 30px;

      /*no*/
      li {
        padding: 0 5px;
        line-height: 24px;
        height: 24px;
      }

      .activeTime {
        color: @primary-color;
      }

      li:hover {
        background: @hover-color;
        color: @text-color-secondary;
      }
    }

    .symbol_name {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: left;
      height: 44px;
      /*no*/
      line-height: 44px;
      /*no*/
      border-bottom: 1px solid @border-color-base;
      padding-left: 16px;

      .symbol_price {
        line-height: 20px;
        /*no*/
        margin-left: 40px;
        padding-top: 3px;
        /*no*/
        width: 150px;

        .symbol_offerData {
          font-size: 14px;
          font-weight: 600;
        }

        span {
          font-size: 12px;
          /*no*/
          font-weight: 400;
        }

        .pre {
          margin-left: 10px;
          /*no*/
        }
      }

      .high,
      .low {
        font-size: 12px;
        /*no*/
        line-height: 20px;
        /*no*/
        padding-top: 3px;

        /*no*/
        div {
          &:first-child {
            color: @text-color-secondary;
          }
        }
      }

      .low {
        margin-left: 40px;
        /*no*/
      }
    }

    .operation-time {
      display: flex;
      justify-content: left;
      height: 30px;
      /*no*/
      line-height: 30px;
      /*no*/
      padding-left: 16px;
    }
  }

  .operation_icon {
    margin-left: 32px;
    color: @description-color;
    display: flex;
    align-items: center;
    .span {
      background: #fb741b;
      font-size: 10px;
      color: white;
      border-radius: 2px;
      cursor: pointer;
      height: 20px;
      padding: 0 5px;
      line-height: 20px;
      text-align: center;
      margin-right: 25px;
    }
    i {
      margin-right: 18px;
      cursor: pointer;
    }
  }
  .transaction_bottom_deal {
    position: absolute;
    padding: 10px;
    top: 100px; /*no*/
    left: 55px; /*no*/
  }
}

#trade-view {
  height: 85%;
}
</style>
